<template>
  <div>
    <b-nav card-header pills class="mb-0 mt-2 px-2">
      <b-nav-item
        exact-active-class="active bg-info box-shadow-info info"
        :link-classes="['px-3', bgTabsNavs]"
        exact
        :to="{ name: 'sn-list-leads-business' }"
        >BUSINESS
      </b-nav-item>
      <b-nav-item
        exact-active-class="active bg-info box-shadow-info info"
        :link-classes="['px-3', bgTabsNavs]"
        exact
        :to="{ name: 'sn-list-leads-credit-experts' }"
        >CREDIT EXPERTS
      </b-nav-item>
      <b-nav-item
        exact-active-class="active bg-info box-shadow-info info"
        :link-classes="['px-3', bgTabsNavs]"
        exact
        :to="{ name: 'sn-list-leads-tax-research' }"
        >TAX RESEARCH
      </b-nav-item>
      <b-nav-item
        exact-active-class="active bg-info box-shadow-info info"
        :link-classes="['px-3', bgTabsNavs]"
        exact
        :to="{ name: 'sn-list-leads-boost-credit' }"
        >BOOST CREDIT
      </b-nav-item>
      <b-nav-item
        exact-active-class="active bg-info box-shadow-info info"
        :link-classes="['px-3', bgTabsNavs]"
        exact
        :to="{ name: 'sn-list-leads-debt-solution' }"
        >DEBT SOLUTION
      </b-nav-item>

      <b-nav-item
        exact-active-class="active bg-info box-shadow-info info"
        :link-classes="['px-3', bgTabsNavs]"
        exact
        :to="{ name: 'sn-list-leads-not-call' }"
        >NOT CALL
      </b-nav-item>
    </b-nav>

    <b-card
      no-body
      class="border-top-info border-3 border-table-radius px-2"
    >
      <router-view :key="$route.name"/>
    </b-card>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import GlobalSearchComponent from '../../../../commons/GlobalSearchComponent.vue'
export default {
  components: {
    "global-search-component": GlobalSearchComponent,
  },
  data() {
    return {
      //
    };
  },
  methods: {
    //
  },
};
</script>
<style scoped>
  /*  */
</style>